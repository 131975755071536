@font-face {
	font-family: 'Amarante';
	src: url(./Amarante/Amarante-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Black Ops One';
	src: url(./Black_Ops_One/BlackOpsOne-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Bree Serif';
	src: url(./Bree_Serif/BreeSerif-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Cantora One';
	src: url(./Cantora_One/CantoraOne-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Courgette';
	src: url(./Courgette/Courgette-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Francois One';
	src: url(./Francois_One/FrancoisOne.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Grand Hotel';
	src: url(./Grand_Hotel/GrandHotel-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Grand Vibes';
	src: url(./Great_Vibes/GreatVibes-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Khand';
	src: url(./Khand/Khand-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Limelight';
	src: url(./Limelight/Limelight-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Lobster';
	src: url(./Lobster/Lobster-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Oregano';
	src: url(./Oregano/Oregano-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Oswald';
	src: url(./Oswald/Oswald-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Playball';
	src: url(./Playball/Playball-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Rubik One';
	src: url(./Rubik_One/RubikOne-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Sacramento';
	src: url(./Sacramento/Sacramento-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
@font-face {
	font-family: 'Sancreek';
	src: url(./Sancreek/Sancreek-Regular.ttf) format('truetype');
	font-style: normal;
	font-weight: 500;
}
