.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.gray-small-scroll::-webkit-scrollbar {
	width: 6px;
}

.gray-small-scroll::-webkit-scrollbar-track {
	background: #ecebf8;
	padding-left: 15px;
}

.gray-small-scroll::-webkit-scrollbar-thumb {
	background: #272f44;
}

/* Firefox scrollbar styles */
.gray-small-scroll {
	scrollbar-width: thin;
	scrollbar-color: #272f44 #ecebf8;
}

.gray-small-scroll:hover {
	scrollbar-color: #272f44 #d5cdf6;
}

.gray-small-scroll:active {
	scrollbar-color: #272f44 #e5ddf6;
}

.gray-small-scroll::-moz-scrollbar-track {
	background: #ecebf8;
	padding-left: 15px;
}

.gray-small-scroll::-moz-scrollbar-thumb {
	background: #272f44;
}
.help-page section {
	scroll-snap-align: start;
}
*[title-data] {
	/* required */
	position: relative;
	/* decorative */
	cursor: help;
}

*[title-data]:hover::after {
	/* required */
	content: attr(title-data);
	position: absolute;
	background-color: hsla(130, 15%, 95%, 1);
	height: fit-content;
	width: max-content;
	z-index: 99;
	padding: 0.5em;
	border-radius: 0.5em;
	border-width: 1px;
	opacity: 96%;
	border-color: #000c18;
	inset: 0em 0.5em 0em 0.5em;
	font-size: 0.9rem;
	font-weight: 300;
	font-style: italic;
	max-width: 20em;
}
.fullWidthCell {
	border-top: none;
	border-bottom: none;
}
.blue-small-scroll::-webkit-scrollbar {
	width: 4px;
}

.blue-small-scroll::-webkit-scrollbar-track {
	background: #ecebf8;
	padding-left: 15px;
	border-radius: 4px;
}

.blue-small-scroll::-webkit-scrollbar-thumb {
	background: #aeaeae;
	border-radius: 4px;
}
.background-transparent > div {
	background-color: transparent !important;
}
.ReactTags__tagInput {
	border: 1px solid #aeaeae;
	border-radius: 4px;
	padding: 8px;
}
.ReactTags__selected {
	margin-top: 20px;
	width: 100%;
	display: inline-flex;
	overflow: auto;
}
.ReactTags__selected::-webkit-scrollbar {
	height: 5px;
}

.ReactTags__selected::-webkit-scrollbar-track {
	background: #ecebf8;
	border-radius: 5px;
}

.ReactTags__selected::-webkit-scrollbar-thumb {
	background: #9392ad;
	border-radius: 5px;
}
.ReactTags__tag {
	margin-bottom: 10px;
	border: 1px solid #aeaeae;
	border-radius: 4px;
	padding: 8px;
	margin-right: 6px;
	text-wrap: nowrap;
}
.ReactTags__remove {
	margin-left: 8px;
}
.ReactTags__tagInputField {
	width: 100%;
}
.font-name {
	font-size: 20px;
}
